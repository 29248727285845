import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
    return (        
        <footer>
        <hr />
        <Container>
          <Row>
            <Col>
              &copy; {new Date().getFullYear()} TechyDad.
            </Col>
          </Row>
        </Container>
      </footer>
    );
}

export default Footer;