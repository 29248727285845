import { Stack } from "react-bootstrap";
import './TutorialRow.css';
import TutorialCol from "./TutorialCol";

const TutorialRow = (props) => {

    return (
        <Stack direction="horizontal" gap={0} className={props.hPos}>
            <TutorialCol board={props.board} outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={props.gamerow} gamecol={0} hPos={props.hPos} vPos="left" />
            <TutorialCol board={props.board} outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={props.gamerow} gamecol={1} hPos={props.hPos} vPos="vmiddle" />
            <TutorialCol board={props.board} outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={props.gamerow} gamecol={2} hPos={props.hPos} vPos="right" />
        </Stack>
    );
}

export default TutorialRow;