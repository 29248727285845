import './TicTacOuterCol.css';
import TicTacBoard from "./TicTacBoard";
import TicTacSquare from "./TicTacSquare";
import { GameContext } from './MainPage';
import { useContext } from "react";

const TicTacOuterCol = (props) => {
    const { board } = useContext(GameContext);

    let classes = props.vPos; 

    let content = "";
    if (board[props.gamerow][props.gamecol].winner === "") {
        content = <TicTacBoard outergamerow={props.gamerow} outergamecol={props.gamecol} />;
    } else {
        content = <TicTacSquare location="outer" gamerow={props.gamerow} gamecol={props.gamecol} vPos={props.vPos} hPos={props.hPos} />;
    }
    return (
        <div className={classes}>
            {/* <div>
                Winner: {board[props.gamerow][props.gamecol].winner}
            </div>
            <div>
                Enabled: {board[props.gamerow][props.gamecol].enabled ? "true" : "false"}
            </div> */}
            {content}
        </div>
    );
}

export default TicTacOuterCol;