import { Col, Container, Row } from "react-bootstrap";

const HeaderStats = () => {
    let XWins = parseInt(localStorage.getItem("Stats-XWins") || "0");
    let OWins = parseInt(localStorage.getItem("Stats-OWins") || "0");
    let Ties = parseInt(localStorage.getItem("Stats-Ties") || "0");
    let TotalGames = parseInt(localStorage.getItem("Stats-TotalGames") || "0");
    let XWinsPct = "";
    let OWinsPct = "";
    let TiesPct = "";
    if (!(TotalGames === 0)) {
        XWinsPct = " (" + parseFloat(100 * XWins / TotalGames).toFixed(2).toLocaleString("en-US") + "%)";
        OWinsPct = " (" + parseFloat(100 * OWins / TotalGames).toFixed(2).toLocaleString("en-US") + "%)";
        TiesPct = " (" + parseFloat(100 * Ties / TotalGames).toFixed(2).toLocaleString("en-US") + "%)";
    }

    return (
        <Container>
            <Row>
                <Col>
                    <p>
                        <span className="headerBold">Human Player (X) Wins:</span> {XWins.toLocaleString("en-US")}{XWinsPct}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        <span className="headerBold">Computer Player (O) Wins:</span> {OWins.toLocaleString("en-US")}{OWinsPct}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        <span className="headerBold">Ties:</span> {Ties.toLocaleString("en-US")}{TiesPct}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        <span className="headerBold">Total Games:</span> {TotalGames.toLocaleString("en-US")}
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default HeaderStats;