export const versionHistory = {
    "plannedFeatures": [
        "Better Computer Player"
    ],
    "history": [
        {
            "version": "2.8",
            "releaseDate": "2022-11-14",
            "features": [
                "Improved computer's responses.",
                "Removed 'Short Name' so the application's name won't show as 'SprTicTacToe'.",
            ]
        },
        {
            "version": "2.7",
            "releaseDate": "2022-10-30",
            "features": [
                "Added a tutorial.",
                "Moved the vSauce video into Credits.",
                "Added Stats feature. (How often can you beat the computer?)"
            ]
        },
        {
            "version": "2.6",
            "releaseDate": "2022-10-17",
            "features": [
                "Fixed a bug - Computer player was still ignoring some O-O style wins/blocks despite the previous bug fix."
            ]
        },
        {
            "version": "2.6",
            "releaseDate": "2022-10-15",
            "features": [
                "Added this cool version history section.",
                "Fixed a bug - When leaving and returning to a 1 player game, game turned into a 2 player game.",
                "Fixed a bug - Computer player ignored O-O style wins/blocks. Computer can now spot them and act accordingly."
            ]
        },
        {
            "version": "2.5",
            "releaseDate": "2022-10-12",
            "features": [
                "Improved the computer player's logic when choosing a square."
            ]
        },
        {
            "version": "2.0",
            "releaseDate": "2022-10-09",
            "features": [
                "Added computer player for 1 player games."
            ]
        },
        {
            "version": "1.0",
            "releaseDate": "2022-09-18",
            "features": [
                "Improved mobile friendliness",
                "Game will save as you go. (No more refreshing causing the game to reset.)"
            ]
        },
        {
            "version": "0.5",
            "releaseDate": "2022-09-10",
            "features": [
                "Initial release"
            ]
        }
    ]
};