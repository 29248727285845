import { Col } from "react-bootstrap";
import './TicTacCol.css';
import TicTacSquare from "./TicTacSquare";

const TicTacCol = (props) => {
    let classes = props.vPos + " " + props.location; 

    return (
        <Col className={classes}>
            <TicTacSquare location="inner" outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={props.gamerow} gamecol={props.gamecol} vPos={props.vPos} hPos={props.hPos} />
        </Col>
    );
}

export default TicTacCol;