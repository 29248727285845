import { Stack } from "react-bootstrap";
import './TicTacRow.css';
import TicTacCol from "./TicTacCol";

const TicTacRow = (props) => {

    return (
        <Stack direction="horizontal" gap={0} className={props.hPos}>
            <TicTacCol outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={props.gamerow} gamecol={0} hPos={props.hPos} vPos="left" />
            <TicTacCol outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={props.gamerow} gamecol={1} hPos={props.hPos} vPos="vmiddle" />
            <TicTacCol outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={props.gamerow} gamecol={2} hPos={props.hPos} vPos="right" />
        </Stack>
    );
}

export default TicTacRow;