export const tutorialScreens = {
    "steps": [
        {
            "stepNumber": "1",
            "caption": "Ah, Tic-Tac-Toe. Such an easy game. So simple. So boring. Let's spice it up a bit.",
            "board": [
                [
                    {
                        winner: " ",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }, 
                    {
                        winner: "X",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: " ",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "O",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "X",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: " ",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: " ",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                    ]
                    },
                    {
                        winner: " ",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "O",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ]
              ]
        },
        {
            "stepNumber": "3",
            "caption": "Here is Super Tic-Tac-Toe!",
            "board": [
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }, 
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                    ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ]
              ]
        },
        {
            "stepNumber": "4",
            "caption": "Inside every square of Super Tic-Tac-Toe is another game of Tic-Tac-Toe.",
            "board": [
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }, 
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                    ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ]
              ]
        },
        {
            "stepNumber": "5",
            "caption": "This might seem confusing at first, but it isn't too hard. Let's walk through a game.",
            "board": [
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }, 
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                    ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ]
              ]
        },
        {
            "stepNumber": "6",
            "caption": "Let's say X goes here.",
            "board": [
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }, 
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["X", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                    ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ]
              ]
        },
        {
            "stepNumber": "7",
            "caption": "Since X went in a top left square, O has to play within the top left game.",
            "board": [
                [
                    {
                        winner: "",
                        enabled: true,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }, 
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["X", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                    ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ]
              ]
        },
        {
            "stepNumber": "8",
            "caption": "If O goes in the center square, then X needs to play in the middle game.",
            "board": [
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "O", ""],
                            ["", "", ""]
                        ]
                    }, 
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: true,
                        game: [
                            ["X", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                    ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ]
              ]
        },
        {
            "stepNumber": "9",
            "caption": "If a player gets three in a row in a game, that game is marked for them.",
            "board": [
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "O", ""],
                            ["", "", ""]
                        ]
                    }, 
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "X",
                        enabled: false,
                        game: [
                            ["X", "", ""],
                            ["", "X", ""],
                            ["", "", "X"]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                    ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "O", ""],
                            ["", "", ""]
                        ]
                    }
                ]
              ]
        },
        {
            "stepNumber": "10",
            "caption": "If a player should be required to play in a game that has already been won, they can play in any game.",
            "board": [
                [
                    {
                        winner: "",
                        enabled: true,
                        game: [
                            ["", "", ""],
                            ["", "O", ""],
                            ["", "", ""]
                        ]
                    }, 
                    {
                        winner: "",
                        enabled: true,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: true,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: true,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "X",
                        enabled: false,
                        game: [
                            ["X", "", ""],
                            ["", "X", ""],
                            ["", "", "X"]
                        ]
                    },
                    {
                        winner: "",
                        enabled: true,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: true,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                    ]
                    },
                    {
                        winner: "",
                        enabled: true,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: true,
                        game: [
                            ["", "", ""],
                            ["", "O", ""],
                            ["", "", ""]
                        ]
                    }
                ]
              ]
        },
        {
            "stepNumber": "11",
            "caption": "The winner is the player who wins three games in a row.",
            "board": [
                [
                    {
                        winner: "O",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }, 
                    {
                        winner: "O",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "X",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "O",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "X",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["O", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "X",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                    ]
                    },
                    {
                        winner: "X",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "O", ""],
                            ["X", "O", ""],
                            ["", "", ""]
                        ]
                    }
                ]
              ]
        },
        {
            "stepNumber": "99",
            "caption": "Now, let's play some Super Tic-Tac-Toe! Good luck.",
            "board": [
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }, 
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ],
                [
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                    ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    },
                    {
                        winner: "",
                        enabled: false,
                        game: [
                            ["", "", ""],
                            ["", "", ""],
                            ["", "", ""]
                        ]
                    }
                ]
              ]
        }
    ] 
};