import { Carousel, Col, Container, Row } from "react-bootstrap";
import React from "react";

import './Tutorial.css';
import TutorialOuterBoard from "./Tutorial/TutorialOuterBoard";
import { tutorialScreens } from "../utility/tutorial-screens";
import { GameContext } from './MainPage';
import { useContext } from "react";

const Tutorial = () => {
    
    const { setTutorialMode } = useContext(GameContext);

    const quitTutorialButtonHandler = () => {
        setTutorialMode("0");
    }

    const caroselItem = (step) => {
        let innerContent = "";
        if (step.stepNumber === "99") {
            innerContent = 
                <Container className="TutorialFinal">
                    <Row>
                        <Col>
                            <button 
                                    className="btn btn-success" 
                                    onClick={quitTutorialButtonHandler}
                            >
                                Exit Tutorial
                            </button>
                        </Col>
                    </Row>
                </Container>;
        } else {
            innerContent = <TutorialOuterBoard board={step.board} />;
        }

        let content = (
            <Carousel.Item key={step.stepNumber}>
                <div>{ step.caption}</div>
                { innerContent }
            </Carousel.Item>
        );

        return content;
    }
    
    return (
        <Container className="tutorial">
            <Row>
                <Col>
                    <Carousel data-bs-theme="dark" interval={null} wrap={false}>
                        {
                            tutorialScreens.steps.map((step) => {
                                return caroselItem(step);
                            })
                        }
                    </Carousel>
                </Col>
            </Row>
        </Container>
    );
}

export default Tutorial;