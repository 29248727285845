import { Stack } from "react-bootstrap";
import './TutorialRow.css';
import TutorialOuterCol from "./TutorialOuterCol";

const TutorialOuterRow = (props) => {

    return (
        <Stack direction="horizontal" gap={0} className={props.hPos}>
            <TutorialOuterCol board={props.board} gamerow={props.gamerow} gamecol={0} hPos={props.hPos} vPos="left" />
            <TutorialOuterCol board={props.board} gamerow={props.gamerow} gamecol={1} hPos={props.hPos} vPos="vmiddle" />
            <TutorialOuterCol board={props.board} gamerow={props.gamerow} gamecol={2} hPos={props.hPos} vPos="right" />
        </Stack>
    );
}

export default TutorialOuterRow;