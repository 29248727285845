import { useState } from "react";
import { Button, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
//import YoutubeEmbed from "./YouTubeEmbed";
import { versionHistory } from "../../utility/version-history";

import "./Header.css";
import HeaderStats from "./HeaderStats";

const Header = () => {
    const [showStats, setShowStats] = useState(false);
    const [showCredits, setShowCredits] = useState(false);
    const [showVersionHistory, setShowVersionHistory] = useState(false);
    const [seed, setSeed] = useState(0);
  
    const handleCloseStats = () => setShowStats(false);
    const handleShowStats = () => setShowStats(true);
    const handleCloseCredits = () => setShowCredits(false);
    const handleShowCredits = () => setShowCredits(true);
    const handleCloseVersionHistory = () => setShowVersionHistory(false);
    const handleShowVersionHistory = () => setShowVersionHistory(true);

    const clearStatsHandler = () => {
        localStorage.setItem("Stats-XWins", 0);
        localStorage.setItem("Stats-OWins", 0);
        localStorage.setItem("Stats-Ties", 0);
        localStorage.setItem("Stats-TotalGames", 0);
        setSeed(seed + 1);
    }

    return (        
        <Navbar bg="dark" data-bs-theme="dark" key={false} expand="sm" className="navbar-dark">
            <Container>
                <Navbar.Brand>
                    <img
                        src="/images/tic-tac-toe.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    />
                    &nbsp;
                    <span className='headerText'>Super Tic-Tac-Toe!</span>
                </Navbar.Brand>
                
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link onClick={handleShowStats}>
                            Stats
                        </Nav.Link>
                        <Nav.Link onClick={handleShowCredits}>
                            Credits
                        </Nav.Link>
                        <Nav.Link onClick={handleShowVersionHistory}>
                            Version History
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
            <Modal show={showStats} onHide={handleCloseStats}>
                <Modal.Header closeButton>
                    <Modal.Title>Stats</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HeaderStats seed={seed} />
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col className="text-left">
                                <Button variant="danger" className="text-left" onClick={clearStatsHandler}>
                                    Clear Stats
                                </Button>
                            </Col>
                            <Col className="text-right">
                                <Button variant="primary" onClick={handleCloseStats}>
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            <Modal show={showCredits} onHide={handleCloseCredits}>
                <Modal.Header closeButton>
                    <Modal.Title>Credits</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        &bull;&nbsp;Credit goes to vSauce for introducing me to Super Tic Tac Toe. <a
                                href="https://www.youtube.com/watch?v=_Na3a1ZrX7c"
                                target="_blank"
                                rel="noreferrer"
                            >Watch his amazing video here.</a>
                    </p>
                    <p>
                        &bull;&nbsp;The Tic-Tac-Toe image in the header was <a 
                            href="https://commons.wikimedia.org/wiki/File:Tic-tac-toe.png" 
                            target="_blank" 
                            rel="noreferrer"
                        >created by Jacob Belanger</a> and is licensed under the Creative Commons Attribution-Share Alike 4.0 International license.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseCredits}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showVersionHistory} onHide={handleCloseVersionHistory} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Version History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Future Plans</h5>
                    <div>
                        <ul>
                        {
                            versionHistory.plannedFeatures.map((item) => {
                                return <li key={item}>{item}</li>;
                            })
                        }
                        </ul>
                    </div>
                    <h5>Release History</h5>
                    <div>
                        <ul>
                        {
                            versionHistory.history.map((item) => {
                                return (
                                    <li key={item.version}>
                                        <h6>Version {item.version}</h6>
                                        <div>Release Date: {item.releaseDate}</div>
                                        <div>
                                            <ul>
                                                {
                                                    item.features.map((feature) => {
                                                        return <li key={feature}>{feature}</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                );
                            })
                        }
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseVersionHistory}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Navbar>
    );
}

export default Header;