import { Col, Container, Row } from "react-bootstrap";
import './PlayerTracker.css';
import { GameContext } from './MainPage';
import React, { useContext, useEffect } from "react";


const PlayerTracker = () => {
    const { initGameState } = useContext(GameContext);
    const { winner, setWinner } = useContext(GameContext);
    const { player, setPlayer } = useContext(GameContext);
    const { numPlayers, setNumPlayers } = useContext(GameContext);
    const { board, setBoard } = useContext(GameContext);
    const { setLastMove } = useContext(GameContext);
    const { setTutorialMode } = useContext(GameContext);
    const { setGameLoadedFromStore } = useContext(GameContext);

    const BeginGame = () => {
        setWinner("");
        setPlayer("X");
        setGameLoadedFromStore(false);
        
        for (let i = 0; i < board.length; i++) {
            for (let j = 0; j < board[i].length; j++) {
                board[i][j] = JSON.parse(JSON.stringify(initGameState));
                board[i][j].enabled = true;
            }
        }
    }
    const RunTutorial = () => {
        setTutorialMode("1");
    }
    const Begin1PlayerGame = () => {
        setNumPlayers(1);
        BeginGame();
    }
    const Begin2PlayerGame = () => {
        setNumPlayers(2);
        BeginGame();
    }
    const SwitchPlayerMode = () => {
        if (numPlayers === 1) {
            setNumPlayers(2);
        } else {
            setNumPlayers(1);
        }
    }
    const QuitGame = () => {
        setWinner("");
        setPlayer("");
        setNumPlayers(0);

        localStorage.removeItem("lastMove");
        localStorage.removeItem("winner");
        localStorage.removeItem("board");
        localStorage.removeItem("player");  
        localStorage.removeItem("numplayers");   
    }

    let content;
    let showExitGameButton = true;
    let showBeginGameButton = false;
    useEffect(() => {
        if ((winner === "") && (player === "")) {
            // Try to restore from local saved
            let storedWinner = localStorage.getItem("winner");
            let storedPlayer = localStorage.getItem("player");
            
            if ((storedWinner !== null) && (storedWinner === "") 
                    && (storedPlayer !== null) && (storedPlayer !== "")) {
                let storedLastMove = JSON.parse(localStorage.getItem("lastMove"));
                let storedBoard = JSON.parse(localStorage.getItem("board"));
                let storedNumPlayers = parseInt(localStorage.getItem("numplayers"));
                
                setWinner(storedWinner);
                setPlayer(storedPlayer);
                setNumPlayers(storedNumPlayers);
                setBoard(storedBoard);
                setLastMove(storedLastMove);
                setGameLoadedFromStore(true);
            }
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (winner === "") {
        if (player === "") {
            showExitGameButton = false;
            showBeginGameButton = true;
        } else {
            content = "The current player is " + player + ".";
        }
    } else {
        if (winner === "T") {
            content = "It's a tie!";
            showExitGameButton = false;
        } else {
            content = winner + " wins! Congrats!"
            showExitGameButton = false;
        }
    }
    let showPlayerChangeBTN = false;
    if (window.location.hostname === "localhost" && window.location.port === "3000") {
        showPlayerChangeBTN = true;
    }

    return (
        <Container>
            <Row>
                { content !== "" ?
                    <Col className="text-center">
                        {content}
                        {/* {
                            winner !== ""
                            ?
                            <div><button className="btn btn-success playerTracker" onClick={BeginGame}>Begin New Game!</button></div>
                            : ""
                        } */}
                    </Col>
                    : ""
                }
                {
                    showExitGameButton ?
                        <React.Fragment>
                            {
                                showPlayerChangeBTN ?
                                <Col xs={4}>
                                    <button className="btn btn-secondary playerTracker" onClick={SwitchPlayerMode}>
                                        {
                                            numPlayers === 1 ? "Change to 2 Player" : "Change to 1 Player"
                                        }
                                    </button>
                                </Col> : null
                            }
                            <Col xs={3}>
                                <button className="btn btn-danger playerTracker" onClick={QuitGame}>Quit Game</button>
                            </Col>   
                        </React.Fragment>
                    : ""
                }
            </Row>
            {
                (winner !== "") ?
                <Row>
                    <Col xs={4}>
                        <button className="btn btn-success playerTracker" onClick={RunTutorial}>Tutorial</button>
                    </Col>
                    <Col xs={4}>
                        <button className="btn btn-success playerTracker" onClick={Begin1PlayerGame}>Begin New 1 Player Game!</button>
                    </Col>
                    <Col xs={4}>
                        <button className="btn btn-success playerTracker" onClick={Begin2PlayerGame}>Begin New 2 Player Game!</button>
                    </Col>
                </Row>   
                : ""
            }
            {
                showBeginGameButton ?
                <Row>
                    <Col xs={4}>
                        <button className="btn btn-success playerTracker" onClick={RunTutorial}>Tutorial</button>
                    </Col>
                    <Col xs={4}>
                        <button className="btn btn-success playerTracker" onClick={Begin1PlayerGame}>Begin 1 Player Game!</button>
                    </Col>
                    <Col xs={4}>
                        <button className="btn btn-success playerTracker" onClick={Begin2PlayerGame}>Begin 2 Player Game!</button>
                    </Col>
                </Row>   
                : ""
            }
        </Container>
    );
}

export default PlayerTracker;