import './TicTacSquare.css';
import { GameContext } from './MainPage';
import React, { useContext } from "react";

const TicTacSquare = (props) => {
    const { lastMove, setLastMove } = useContext(GameContext);
    const { board } = useContext(GameContext);
    const { numPlayers } = useContext(GameContext);

    const afterMoveBoardEvaluation = () => {
        let newLastMove = [props.outergamerow, props.outergamecol, props.gamerow, props.gamecol];
        setLastMove(newLastMove);
    }

    let content = "";
    //let locationValue = (typeof props.location === undefined) ? "inner" : props.location;
    let locationValue = (typeof props.location === "undefined") ? "inner" : props.location;
   
    let fadeIn = false;
    if (locationValue === "inner") {
        if (numPlayers === 1) {
            if (props.outergamerow === lastMove[0] 
                    && props.outergamecol === lastMove[1] 
                    && props.gamerow === lastMove[2] 
                    && props.gamecol === lastMove[3]) {
                fadeIn = true;
            }
        }
        content = board[props.outergamerow][props.outergamecol].game[props.gamerow][props.gamecol];
    } else {
        content = board[props.gamerow][props.gamecol].winner;
    }
    if (content === "") {
        if (board[props.outergamerow][props.outergamecol].enabled) {
            content =   <div>
                            <button
                                className='btn btn-primary playButton' 
                                onClick={afterMoveBoardEvaluation}
                            >&nbsp;</button>
                        </div>;
            //content = <div className={classes} onClick={afterMoveBoardEvaluation}>{"\u25A0"}</div>;
        } else {
            content = <button className='btn btn-disabled playButton' disabled>&nbsp;</button>;
            let classes = locationValue + "WinnerSquare";
            content = <div className={classes}>&nbsp;</div>;
            // For Testing
            //content = <button className='btn btn-disabled playButton' disabled={false} onClick={afterMoveBoardEvaluation}>[]</button>;
        }
    } else {
        let classes = locationValue + "WinnerSquare";
        content = <div className={classes}>{content}</div>;
    }
    if (fadeIn) {
        return (
            <React.Fragment><span className='computerPlayer'>{content}</span></React.Fragment>
        );    
    }
    return (
        <React.Fragment>{content}</React.Fragment>
    );
}

export default TicTacSquare;