import React, { useEffect, useState } from 'react';
import Header from './display/Header';
import Footer from './display/Footer';
import TicTacOuterBoard from './TicTacOuterBoard';
import PlayerTracker from './PlayerTracker';
import { checkBoardWinner, updateBoard, computerPlayer } from '../utility/utility-functions';
import Tutorial from './Tutorial';

export const GameContext = React.createContext(null);

const MainPage = () => {
    const initGame = {
        winner: "",
        enabled: false,
        game: [
            ["", "", ""],
            ["", "", ""],
            ["", "", ""]
        ]
      };
    const initBoard = [
      [initGame, initGame, initGame],
      [initGame, initGame, initGame],
      [initGame, initGame, initGame]
    ];
    const [lastMove, setLastMove] = useState(["","","",""]);
    const [initGameState] = useState(initGame);
    const [winner, setWinner] = useState("");
    const [board, setBoard] = useState(initBoard);
    const [player, setPlayer] = useState("");
    const [numPlayers, setNumPlayers] = useState("");
    const [tutorialMode, setTutorialMode] = useState("0");
    const [gameLoadedFromStore, setGameLoadedFromStore] = useState(false);
  
  useEffect(() => {
    if (player !== "") {
      let boardJSONString = JSON.stringify(board);
      let tempBoardJSONString = JSON.stringify(updateBoard(board, lastMove, player));
      let tempBoard = JSON.parse(tempBoardJSONString);

      if (boardJSONString !== tempBoardJSONString) {
        setBoard(JSON.parse(tempBoardJSONString));
      }

      let theWinner = checkBoardWinner(tempBoard);
      if (theWinner !== "") {
        setWinner(theWinner);
        
        if (!(gameLoadedFromStore)) {
          let XWins = parseInt(localStorage.getItem("Stats-XWins") || "0");
          let OWins = parseInt(localStorage.getItem("Stats-OWins") || "0");
          let Ties = parseInt(localStorage.getItem("Stats-Ties") || "0");
          let TotalGames = parseInt(localStorage.getItem("Stats-TotalGames") || "0");

          TotalGames++;
          if (theWinner === "X") {
            XWins++;
          } else if (theWinner === "O") {
            OWins++;
          } else {
            Ties++;
          }

          localStorage.setItem("Stats-XWins", XWins);
          localStorage.setItem("Stats-OWins", OWins);
          localStorage.setItem("Stats-Ties", Ties);
          localStorage.setItem("Stats-TotalGames", TotalGames);
        }
      } else {
        if (player === "X") {
            setPlayer("O");
            if (numPlayers === 1) {
              let computerMove = computerPlayer(tempBoard);
              setLastMove(computerMove);
            }
        } else {
            setPlayer("X");
        }
      }

      localStorage.setItem("lastMove", JSON.stringify(lastMove));
      localStorage.setItem("winner", winner);
      localStorage.setItem("board", JSON.stringify(board));
      localStorage.setItem("player", player);    
      localStorage.setItem("numplayers", numPlayers);    

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMove]);

  let content = <GameContext.Provider  value={{
    winner: winner, 
    setWinner: setWinner, 
    board: board, 
    setBoard: setBoard, 
    player: player, 
    setPlayer: setPlayer, 
    initGame: initGame, 
    initGameState: initGameState, 
    lastMove: lastMove, 
    setLastMove: setLastMove,
    numPlayers: numPlayers,
    setNumPlayers: setNumPlayers,
    tutorialMode: tutorialMode,
    setTutorialMode: setTutorialMode,
    gameLoadedFromStore: gameLoadedFromStore,
    setGameLoadedFromStore: setGameLoadedFromStore
  }}>
    <PlayerTracker />
    <TicTacOuterBoard />
  </GameContext.Provider>;
  
  if (tutorialMode === "1") {
    content = <GameContext.Provider  value={{
      setTutorialMode: setTutorialMode
    }}>
      <Tutorial />
    </GameContext.Provider>;  }

  return (
    <div className="App">
      <Header />
      
      { content }

      <Footer />
    </div>
  );
}

export default MainPage;
