import { Stack } from "react-bootstrap";
import TutorialRow from "./TutorialRow";
import './TutorialBoard.css';

const TutorialBoard = (props) => {
    return (
        <div>
            <Stack direction="vertical" gap={0} className="inner">
                <TutorialRow board={props.board} outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={0} hPos="top" />
                <TutorialRow board={props.board} outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={1} hPos="hmiddle" />
                <TutorialRow board={props.board} outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={2} hPos="bottom" />
            </Stack>
        </div>
    );
}

export default TutorialBoard;