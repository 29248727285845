import './TutorialOuterCol.css';
import TutorialBoard from "./TutorialBoard";
import TutorialSquare from "./TutorialSquare";

const TutorialOuterCol = (props) => {

    let classes = props.vPos; 

    let content = "";
    if (props.board[props.gamerow][props.gamecol].winner === "") {
        content = <TutorialBoard board={props.board} outergamerow={props.gamerow} outergamecol={props.gamecol} />;
    } else {
        content = <TutorialSquare board={props.board} location="outer" gamerow={props.gamerow} gamecol={props.gamecol} vPos={props.vPos} hPos={props.hPos} />;
    }
    return (
        <div className={classes}>
            {/* <div>
                Winner: {board[props.gamerow][props.gamecol].winner}
            </div>
            <div>
                Enabled: {board[props.gamerow][props.gamecol].enabled ? "true" : "false"}
            </div> */}
            {content}
        </div>
    );
}

export default TutorialOuterCol;