import { gameWins } from "./game-wins";

export const checkBoardHasOpenSpots = (board) => {
    for (let i = 0; i < board.length; i++) {
        for (let j = 0; j < board[i].length; j++) {
            if (board[i][j].winner === "") {
                return true;
            }
        }
    }    
    return false;
}


export const checkHasOpenSpots = (board, row, col) => {
     let game = board[row][col].game;

    for (let i = 0; i < game.length; i++) {
        for (let j = 0; j < game[i].length; j++) {
            if (game[i][j] === "") {
                return true;
            }
        }
    }    
    return false;
}

export const checkWinner = (board, row, col) => {
    let tempgame = board[row][col].game;

    let theWinner = "";
    if (tempgame[0][0] !== "") {
        if (
                (tempgame[0][0] === 
                    tempgame[0][1]) 
                && (tempgame[0][1] === 
                    tempgame[0][2])) {
            theWinner = tempgame[0][0];
        }
        if (
                (tempgame[0][0] === 
                    tempgame[1][0]) 
                && (tempgame[1][0] === 
                    tempgame[2][0])) {
            theWinner = tempgame[0][0];
        }
        if (
                (tempgame[0][0] === 
                    tempgame[1][1]) 
                && (tempgame[1][1] === 
                    tempgame[2][2])) {
            theWinner = tempgame[0][0];
        }
    }
    if (tempgame[0][1] !== "") {
        if (
                (tempgame[0][1] === 
                        tempgame[1][1]) 
                && (tempgame[1][1] === 
                        tempgame[2][1])) {
            theWinner = tempgame[0][1];
        }
    }
    if (tempgame[0][2] !== "") {
        if (
                (tempgame[0][2] === 
                    tempgame[1][2]) 
                && (tempgame[1][2] === 
                    tempgame[2][2])) {
            theWinner = tempgame[0][2];
        }
        if (
                (tempgame[0][2] === 
                    tempgame[1][1]) 
                && (tempgame[1][1] === 
                    tempgame[2][0])) {
            theWinner = tempgame[0][2];
        }
    }
    if (tempgame[1][0] !== "") {
        if (
                (tempgame[1][0] === 
                    tempgame[1][1]) 
                && (tempgame[1][1] === 
                    tempgame[1][2])) {
            theWinner = tempgame[1][0];
        }
    } 
    if (tempgame[2][0] !== "") {
        if (
                (tempgame[2][0] === 
                    tempgame[2][1]) 
                && (tempgame[2][1] === 
                    tempgame[2][2])) {
            theWinner = tempgame[2][0];
        }
    }
    if (theWinner === "") {
        if (!(checkHasOpenSpots(board, row, col))) {             
            theWinner = "T";
        }
    } 
    return theWinner;
}

export const checkBoardWinner = (board) => {    
    let theWinner = "";
    if (board[0][0].winner !== "") {
        if (
                (board[0][0].winner === 
                    board[0][1].winner) 
                && (board[0][1].winner === 
                    board[0][2].winner)) {
            theWinner = board[0][0].winner;
        }
        if (
                (board[0][0].winner === 
                    board[1][0].winner) 
                && (board[1][0].winner === 
                    board[2][0].winner)) {
            theWinner = board[0][0].winner;
        }
        if (
                (board[0][0].winner === 
                    board[1][1].winner) 
                && (board[1][1].winner === 
                    board[2][2].winner)) {
            theWinner = board[0][0].winner;
        }
    }
    if (board[0][1].winner !== "") {
        if (
                (board[0][1].winner === 
                        board[1][1].winner) 
                && (board[1][1].winner === 
                        board[2][1].winner)) {
            theWinner = board[0][1].winner;
        }
    }
    if (board[0][2].winner !== "") {
        if (
                (board[0][2].winner === 
                    board[1][2].winner) 
                && (board[1][2].winner === 
                    board[2][2].winner)) {
            theWinner = board[0][2].winner;
        }
        if (
                (board[0][2].winner === 
                    board[1][1].winner) 
                && (board[1][1].winner === 
                    board[2][0].winner)) {
            theWinner = board[0][2].winner;
        }
    }
    if (board[1][0].winner !== "") {
        if (
                (board[1][0].winner === 
                    board[1][1].winner) 
                && (board[1][1].winner === 
                    board[1][2].winner)) {
            theWinner = board[1][0].winner;
        }
    } 
    if (board[2][0].winner !== "") {
        if (
                (board[2][0].winner === 
                    board[2][1].winner) 
                && (board[2][1].winner === 
                    board[2][2].winner)) {
            theWinner = board[2][0].winner;
        }
    }
    if (theWinner === "") {
        if (!(checkBoardHasOpenSpots(board))) {             
            theWinner = "T";
        }
    } 
    return theWinner;
}

export const updateBoard = (board, lastMove, player) => {
    let tempBoard = JSON.parse(JSON.stringify(board));

    // Make the move
    let outerrow = lastMove[0];
    let outercol = lastMove[1];
    let innerrow = lastMove[2];
    let innercol = lastMove[3];
    if (Number.isInteger(outerrow) 
            && Number.isInteger(outercol) 
            && Number.isInteger(innerrow) 
            && Number.isInteger(innercol)) {
        tempBoard[outerrow][outercol].game[innerrow][innercol] = player;
        
        // Check winner in played game
        let theWinner = checkWinner(tempBoard, outerrow, outercol);
        if (theWinner !== "") {
            tempBoard[outerrow][outercol].winner = theWinner;
            tempBoard[outerrow][outercol].enabled = false;
        }

        // Check overall winner - don't update winner
        let theBoardWinner = checkBoardWinner(tempBoard);
        if (theBoardWinner !== "") {        
            // Disable all board
            for (let i = 0; i < tempBoard.length; i++) {
                for (let j = 0; j < tempBoard[i].length; j++) {
                    tempBoard[i][j].enabled = false;
                }
            }
        } else {
            // No winner, enable next board for play
            if (tempBoard[innerrow][innercol].winner === "") {
                // Enable board cooresponding to last move
                for (let i = 0; i < tempBoard.length; i++) {
                    for (let j = 0; j < tempBoard[i].length; j++) {
                        if ((innerrow === i) && (innercol === j)) {
                            tempBoard[i][j].enabled = true;
                        } else {
                            tempBoard[i][j].enabled = false;
                        }
                    }
                }    
            } else {
                // Enable all boards
                for (let i = 0; i < tempBoard.length; i++) {
                    for (let j = 0; j < tempBoard[i].length; j++) {
                        if (tempBoard[i][j].winner === "") {
                            tempBoard[i][j].enabled = true;
                        }
                    }
                }    
            }

        }
    }


    return tempBoard;
}



export const computerPlayer = (board) => {
    let tempBoard = JSON.parse(JSON.stringify(board));

    let openSlots = [];

    // Win any boards with 2 O's in a row
    if (openSlots.length === 0) {
        openSlots = randomComputerMoveDouble(tempBoard, "O");
    }
    // Block any boards with 2 X's in a row
    if (openSlots.length === 0) {
        openSlots = randomComputerMoveDouble(tempBoard, "X");
    }
    // Turn 1 O into 2 O's
    if (openSlots.length === 0) {
        openSlots = randomComputerMoveSingleO(tempBoard);
    }
    // Pick a random square to make an O
    if (openSlots.length === 0) {
        openSlots = randomComputerMove(tempBoard);
    }

    let randomNumber = Math.floor(Math.random() * openSlots.length);

    return openSlots[randomNumber];
}


const randomComputerMoveDouble = (tempBoard, checkForPlayer) => {
    // let openSlot = [];
    let openSlots = [];

    for (let i = 0; i < tempBoard.length; i++) {
        for (let j = 0; j < tempBoard[i].length; j++) {
            if ((tempBoard[i][j].enabled) && (tempBoard[i][j].winner === "")) {
                let openSlotsCopy = [...openSlots];
                gameWins.movesToCheck.forEach((item) => {
                        let openSlot = [i, j, item.box1[0], item.box1[1]];
                        if (computerCheckLine(
                            tempBoard[i][j].game, 
                            item.box2,
                            item.box3,
                            openSlot,
                            checkForPlayer,
                            openSlotsCopy
                        )) {
                            openSlots.push(openSlot);
                        }
                        
                        openSlot = [i, j, item.box2[0], item.box2[1]];
                        if (computerCheckLine(
                            tempBoard[i][j].game, 
                            item.box1,
                            item.box3,
                            openSlot,
                            checkForPlayer,
                            openSlotsCopy
                        )) {
                            openSlots.push(openSlot);
                        }
                        
                        openSlot = [i, j, item.box3[0], item.box3[1]];
                        if (computerCheckLine(
                            tempBoard[i][j].game, 
                            item.box1,
                            item.box2,
                            openSlot,
                            checkForPlayer,
                            openSlotsCopy
                        )) {
                            openSlots.push(openSlot);
                        }
                    });
            }
        }
    }    

    return openSlots;
}

const computerCheckLine = (game, check1Array, check2Array, openSlotArray, player, openSlots) => {
    if ((game[check1Array[0]][check1Array[1]] === player) 
            && (game[check2Array[0]][check2Array[1]] === player)) {
        if (game[openSlotArray[2]][openSlotArray[3]] === "") {
            if (!openSlots.includes(openSlotArray)) {
                return true;
            }
        }
    }
    return false;
}

const randomComputerMoveSingleO = (tempBoard) => {
    let openSlots = [];

    for (let i = 0; i < tempBoard.length; i++) {
        for (let j = 0; j < tempBoard[i].length; j++) {
            if ((tempBoard[i][j].enabled) && (tempBoard[i][j].winner === "")) {
                gameWins.movesToCheck.forEach((item) => {
                    let game = tempBoard[i][j].game;
                    let openSlot = [];
                    if (game[item.box1[0]][item.box1[1]] === "O" 
                            && game[item.box2[0]][item.box2[1]] === "" 
                            && game[item.box3[0]][item.box3[1]] === "") {
                        openSlot = [i, j, item.box2[0], item.box2[1]];

                        if (!openSlots.includes(openSlot)) {
                            openSlots.push(openSlot);
                        }
                        
                        openSlot = [i, j, item.box3[0], item.box3[1]];
                        if (!openSlots.includes(openSlot)) {
                            openSlots.push(openSlot);
                        }
                    }
                    if (game[item.box1[0]][item.box1[1]] === "" 
                            && game[item.box2[0]][item.box2[1]] === "O" 
                            && game[item.box3[0]][item.box3[1]] === "") {
                        openSlot = [i, j, item.box1[0], item.box1[1]];
                        if (!openSlots.includes(openSlot)) {
                            openSlots.push(openSlot);
                        }
                        
                        openSlot = [i, j, item.box3[0], item.box3[1]];
                        if (!openSlots.includes(openSlot)) {
                            openSlots.push(openSlot);
                        }
                    }
                    if (game[item.box1[0]][item.box1[1]] === "" 
                            && game[item.box2[0]][item.box2[1]] === "" 
                            && game[item.box3[0]][item.box3[1]] === "O") {
                        openSlot = [i, j, item.box1[0], item.box1[1]];
                        if (!openSlots.includes(openSlot)) {
                            openSlots.push(openSlot);
                        }

                        openSlot = [i, j, item.box2[0], item.box2[1]];
                        if (!openSlots.includes(openSlot)) {
                            openSlots.push(openSlot);
                        }
                    }
                });
            }
        }
    }    

    return openSlots;
}

const randomComputerMove = (tempBoard) => {
    let openSlots = [];

    for (let i = 0; i < tempBoard.length; i++) {
        for (let j = 0; j < tempBoard[i].length; j++) {
            if ((tempBoard[i][j].enabled) && (tempBoard[i][j].winner === "")) {
                for (let k = 0; k < tempBoard[i][j].game.length; k++) {
                    for (let l = 0; l < tempBoard[i][j].game[k].length; l++) {
                        if (tempBoard[i][j].game[k][l] === "") {
                            let openSlot = [i, j, k, l];
                            openSlots.push(openSlot);
                        }
                    }
                }
            }
        }
    }    

    return openSlots;
}