import './TutorialSquare.css';
import React from "react";

const TutorialSquare = (props) => {
    let content = "";
    //let locationValue = (typeof props.location === undefined) ? "inner" : props.location;
    let locationValue = (typeof props.location === "undefined") ? "inner" : props.location;
   
    let fadeIn = false;
    if (locationValue === "inner") {
        content = props.board[props.outergamerow][props.outergamecol].game[props.gamerow][props.gamecol];
    } else {
        content = props.board[props.gamerow][props.gamecol].winner;
    }
    if (content === "") {
        if (props.board[props.outergamerow][props.outergamecol].enabled) {
            content =   <div>
                            <button
                                className='btn btn-primary playButton' 
                            >&nbsp;</button>
                        </div>;
        } else {
            content = <button className='btn btn-disabled playButton' disabled>&nbsp;</button>;
            let classes = locationValue + "WinnerSquare";
            content = <div className={classes}>&nbsp;</div>;
        }
    } else {
        let classes = locationValue + "WinnerSquare";
        if (content === " ") {
            content = <div className={classes}>&nbsp;</div>;
        } else {
            content = <div className={classes}>{content}</div>;
        }
    }
    if (fadeIn) {
        return (
            <React.Fragment><span className='computerPlayer'>{content}</span></React.Fragment>
        );    
    }
    return (
        <React.Fragment>{content}</React.Fragment>
    );
}

export default TutorialSquare;