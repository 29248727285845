import { Stack } from "react-bootstrap";
import './TutorialBoard.css';
import TutorialOuterRow from "./TutorialOuterRow";

const TutorialOuterBoard = (props) => {

    return (
        <Stack direction="vertical" gap={0}>
            <TutorialOuterRow board={props.board} gamerow={0} hPos="top" />
            <TutorialOuterRow board={props.board} gamerow={1} hPos="hmiddle" />
            <TutorialOuterRow board={props.board} gamerow={2} hPos="bottom" />
        </Stack>
    );
}

export default TutorialOuterBoard;