import { Col } from "react-bootstrap";
import './TutorialCol.css';
import TutorialSquare from "./TutorialSquare";

const TutorialCol = (props) => {
    let classes = props.vPos + " " + props.location; 

    return (
        <Col className={classes}>
            <TutorialSquare board={props.board} location="inner" outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={props.gamerow} gamecol={props.gamecol} vPos={props.vPos} hPos={props.hPos} />
        </Col>
    );
}

export default TutorialCol;