import { Stack } from "react-bootstrap";
import TicTacRow from "./TicTacRow";
import './TicTacBoard.css';

const TicTacBoard = (props) => {
    return (
        <div>
            <Stack direction="vertical" gap={0} className="inner">
                <TicTacRow outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={0} hPos="top" />
                <TicTacRow outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={1} hPos="hmiddle" />
                <TicTacRow outergamerow={props.outergamerow} outergamecol={props.outergamecol} gamerow={2} hPos="bottom" />
            </Stack>
        </div>
    );
}

export default TicTacBoard;