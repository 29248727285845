export const gameWins = {
    "movesToCheck": [
        {
            "sketch": [
                " X | X | X ",
                "-----------",
                "   |   |   ",
                "-----------",
                "   |   |   "
            ],
            "box1": [0, 0],
            "box2": [0, 1],
            "box3": [0, 2]
        },
        {
            "sketch": [
                "   |   |   ",
                "-----------",
                " X | X | X ",
                "-----------",
                "   |   |   "
            ],
            "box1": [1, 0],
            "box2": [1, 1],
            "box3": [1, 2]
        },
        {
            "sketch": [
                "   |   |   ",
                "-----------",
                "   |   |   ",
                "-----------",
                " X | X | X "
            ],
            "box1": [2, 0],
            "box2": [2, 1],
            "box3": [2, 2]
        },
        {
            "sketch": [
                " X |   |   ",
                "-----------",
                " X |   |   ",
                "-----------",
                " X |   |   "
            ],
            "box1": [0, 0],
            "box2": [1, 0],
            "box3": [2, 0]
        },
        {
            "sketch": [
                "   | X |   ",
                "-----------",
                "   | X |   ",
                "-----------",
                "   | X |   "
            ],
            "box1": [0, 1],
            "box2": [1, 1],
            "box3": [2, 1]
        },
        {
            "sketch": [
                "   |   | X ",
                "-----------",
                "   |   | X ",
                "-----------",
                "   |   | X "
            ],
            "box1": [0, 2],
            "box2": [1, 2],
            "box3": [2, 2]
        },
        {
            "sketch": [
                " X |   |   ",
                "-----------",
                "   | X |   ",
                "-----------",
                "   |   | X "
            ],
            "box1": [0, 0],
            "box2": [1, 1],
            "box3": [2, 2]
        },
        {
            "sketch": [
                "   |   | X ",
                "-----------",
                "   | X |   ",
                "-----------",
                " X |   |   "
            ],
            "box1": [0, 2],
            "box2": [1, 1],
            "box3": [2, 0]
        }
    ] 
};