import { Stack } from "react-bootstrap";
import './TicTacBoard.css';
import TicTacOuterRow from "./TicTacOuterRow";

const TicTacOuterBoard = (props) => {
    return (
        <Stack direction="vertical" gap={0}>
            <TicTacOuterRow gamerow={0} hPos="top" />
            <TicTacOuterRow gamerow={1} hPos="hmiddle" />
            <TicTacOuterRow gamerow={2} hPos="bottom" />
        </Stack>
    );
}

export default TicTacOuterBoard;